/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Text, Subtitle, Title } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O nás"}>
        <Column className="pb--10 pl--25 pr--25 pt--10" name={"prazdna_sekcee"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1 --full mt--10 pb--10" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="pb--25" style={{"paddingBottom":null}}>
              
              <Image className="swp-T00-mobile --right" src={"https://cdn.swbpg.com/o/2124/8e308098c208425aac7816f98e602e91.svg"} svg={false} sizes="100vw" style={{"maxWidth":45}} srcSet={""} RootClassName={"column__pic"}>
              </Image>

              <Text className="swp-T00-mobile text-box text-box--right mt--02" style={{"maxWidth":497,"marginTop":null}} content={"Jestli potřebujete pomoct, v pravým horním rohu naleznete nápovědu"}>
              </Text>

              <Text className="text-box text-box--left" style={{"maxWidth":488}} content={"Náš příběh se datuje k roku 1997, kdy se náš otec, Roman Lesonický, pracující v oblasti elektroinstalací, rozhodl opustit zaměstnání a stát se osobou samostatně výdělečně činnou. Nebyl to jednoduchý krok, ale čas ukázal, že to byla cesta správným směrem. Od rodinných domů se postupně propracoval k realizaci milionových zakázek. Já, Mgr. Roman Lesonický, společně s bratrem, Ing. Michalem Lesonickým, jsme se podíleli na realizaci většiny zakázek, kde jsme se učili dané řemeslo a věci s nimi spojené. Zároveň jsme se však vzdělávali v ekonomii, managementu, financích či řízení podniku při studiu na vysoké škole. Rok 2020 přinesl spoustu nečekaných změn, ale my se rozhodli posunout rodinný business na další úroveň, kterou je založení firmy RM Elektroinstalace. "}>
              </Text>

              <Subtitle className="subtitle-box mt--25" style={{"marginTop":null}} content={"<span style=\"color: rgb(142, 146, 172);\">Tohle je prozatím jediná sekce vašeho webu.</span>"}>
              </Subtitle>

              <Title className="title-box mt--06" style={{"maxWidth":620,"marginTop":null}} content={"Začněte tím, že tuhle sekci smažete a přidáte si sekci vlastní."}>
              </Title>

              <Image className="--right mt--40" src={"https://cdn.swbpg.com/o/2124/0939ff1d9f92402f8a460fa8a39064fd.svg"} svg={false} sizes="100vw" style={{"maxWidth":45,"marginTop":null}} srcSet={""} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--right mt--02" style={{"maxWidth":429,"marginTop":null}} content={"Pro přidání nové sekce klikněte na \"+\"<br>tam, kde chcete sekci přidat."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}